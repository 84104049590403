import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

Sentry.init({
  enabled: !dev,
  dsn: 'https://8b2148b72c2f69e2b1449bb5279dc814@o4508342152200192.ingest.us.sentry.io/4508342168977408',
  autoSessionTracking: false,
  tracesSampleRate: 100.0
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
