import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/account/forgot": [9,[2]],
		"/account/reset": [6,[2]],
		"/account/signin": [10,[2]],
		"/account/signout": [7,[2]],
		"/account/signup": [8,[2]],
		"/cart": [19,[4]],
		"/cart/checkout": [20,[4]],
		"/cart/complete": [21,[4]],
		"/donation": [~18],
		"/memberships": [~16],
		"/memberships/[slug]": [~17],
		"/profile": [11,[3]],
		"/profile/addresses": [15,[3]],
		"/profile/edit": [14,[3]],
		"/profile/users/add": [13,[3]],
		"/profile/users/edit": [12,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';